<template>
  <div
    class="background-image position-relative d-flex align-items-start flex-column"
  >
    <div class="d-flex justify-content-center flex-row mt-3">
      <div class="w-auto d-flex flex-row gap-3">
        <img class="bmw-logo" src="../assets/images/bmw_logo.svg"  alt=""/>
        <img class="mini-logo" src="../assets/images/mini_logo.svg"  alt=""/>
      </div>
    </div>
    <div class="my-auto page-content p-3 text-center fs-1 fw-bold">
      {{ $t("thankYouForYourTime") }}
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.bmw-logo {
  max-width: 5rem;
}
.mini-logo {
  max-width: 9rem;
}
.background-image {
  height: calc(100vh);

  > div {
    z-index: 2;
    width: 100vw;
  }

  @media (min-width: 800px) {
    .page-content {
      padding: 0 10rem !important;
    }
  }
  @media (min-width: 1280px) {
    .page-content {
      padding: 0 20rem !important;
    }
  }
}
</style>
